import React, { useState, Fragment } from "react";
import { useFetchCustomQueryPotentialsUsersCoursewiseCount } from "../api/fetchData";
import { Skeleton, Text, TableContainer, Table, Thead, Th, Tbody, Tr, Td, useMediaQuery, Input, Card, CardHeader, CardBody, Center } from "@chakra-ui/react";
import moment from "moment";

const PotentialsUsersCoursewiseCount = ({ matrix, module, query }) => {
    const [input, setInput] = useState({
        from: query.from,
        to: query.to
    });

    const [isLagerThan420] = useMediaQuery('(min-width: 420px)');
    const [isLagerThan440] = useMediaQuery('(min-width: 440px)');
    const [isLagerThan600] = useMediaQuery('(min-width: 600px)');
    const [isLagerThan800] = useMediaQuery('(min-width: 800px)');
    const [isLagerThan1590] = useMediaQuery('(min-width: 1590px)');

    const handleInput = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'from':
                if (moment(value).isAfter(input.to) || value === "" || moment(value).isBefore('2024-09-01')) {
                    setInput((prev) => ({
                        ...prev,
                        [name]: input.to
                    }))
                    return false;
                }
                break;
            case 'to':
                if (moment(value).isBefore(input.from) || value === "" || moment(value).isBefore('2024-09-01')) {
                    setInput((prev) => ({
                        ...prev,
                        [name]: input.from
                    }))
                    return false;
                }
                break;
            default:
                break;
        }

        setInput((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const { data, courseWiseTotals, userWiseTotal, grandTotal } = useFetchCustomQueryPotentialsUsersCoursewiseCount(module, input);

    const headers = {
        'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)': 'CC',
        'Hospitality Management [Advanced Diploma] - (Full time - 2 years)': 'HM',
        'Patisserie [Certificate IV] - (Full time - 1.5 years)': 'PS',
        'Event Management [Diploma] - (Part time - 2 years)': 'EM',
        'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)': 'TT'
    };
    const subHeaders = ['F', 'RFS', 'RPP', 'RPC'];

    return (
        <Card
            overflow='hidden'
            variant='outline'
            align='center'
            borderRadius={10}
            marginX={{ base: '5px', sm: '0px', md: '5px', lg: '5px' }}
        >
            <CardHeader>
                <Center
                    {...(!isLagerThan440 ? { flexDirection: 'column' } : {})}
                >
                    From: <Input
                        type='date'
                        name='from'
                        min='2024-09-01'
                        max={input.to}
                        value={input.from}
                        onChange={handleInput}
                    />
                    To: <Input
                        type='date'
                        name='to'
                        min={input.from}
                        value={input.to}
                        onChange={handleInput}
                    />
                </Center>
            </CardHeader>
            <CardBody
                sx={{
                    width: '100%',
                    overflowX: 'scroll',
                    overflowY: 'hidden'
                }}
            >
                <Skeleton fadeDuration={5} height={data ? `${126 + ((isLagerThan1590 ? 46 : isLagerThan800 ? 69 : 58) * Object.keys(data['result']['record']['potentials']).length)}px` : '350px'} isLoaded={data}>
                    {data &&
                        <TableContainer whiteSpace={{ base: "nowrap", xs: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}>
                            <Table size={{ base: 'md', xs: 'xs', sm: 'sm', md: 'md', lg: 'lg' }}>
                                <Thead>
                                    <Tr>
                                        <Th
                                            sx={{
                                                borderColor: 'gray.300',
                                                paddingRight: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important'),
                                                paddingLeft: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important')
                                            }}
                                            rowSpan={2}
                                        >
                                            Users
                                        </Th>
                                        {/* Map over the array and render each <Th> dynamically */}
                                        {Object.entries(headers).map(([header, index]) => (
                                            <Th key={index} sx={{
                                                borderColor: 'gray.300',
                                                paddingRight: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important'),
                                                paddingLeft: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important')
                                            }}
                                                textAlign='center'
                                                colSpan={4}>{index}</Th>
                                        ))}
                                    </Tr>
                                    <Tr>
                                        {Object.entries(headers).map((mainHeaders, indexMain) => (
                                            <Fragment key={`null${indexMain}`}>
                                                {subHeaders.map((header, index) => (
                                                    <Th
                                                        key={`${header}-${indexMain}`}
                                                        sx={{
                                                            borderColor: `black.300`,
                                                            backgroundColor: indexMain === 0 ? '' : `gray.${(indexMain) * 100}`,
                                                            paddingRight: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important'),
                                                            paddingLeft: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important')
                                                        }}
                                                        textAlign='center'
                                                    >{header} </Th>
                                                ))}

                                            </Fragment>
                                        ))}
                                        <Th key="row-total-header" sx={{ borderColor: `black.300`, bg: "yellow.200" }}></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {Object.entries(data['result']['record']['potentials']).map(([user, courses]) => (
                                        <Tr key={`tr-${user}`}>
                                            <Td
                                                key={`user-${user}`}
                                                sx={{
                                                    paddingTop: '10px !important',
                                                    paddingBottom: '10px !important',
                                                    borderColor: 'red.100',
                                                    paddingRight: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important'),
                                                    paddingLeft: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important')
                                                }}
                                            >
                                                <Text key={`txt-${user}`} fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}>{user}</Text>
                                            </Td>
                                            {Object.entries(headers).map(([mainHeaders, indexMain]) => (
                                                <Fragment key={`null${mainHeaders}-${indexMain}-${user}`}>
                                                    {subHeaders.map((header, index) => (
                                                        <Td
                                                            key={`${mainHeaders}-${header}-${user}`}
                                                            textAlign='center'
                                                            sx={{
                                                                paddingTop: '10px !important',
                                                                paddingBottom: '10px !important',
                                                                borderColor: 'blue.600',

                                                                paddingRight: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important'),
                                                                paddingLeft: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important')
                                                            }}
                                                        >
                                                            <Text
                                                                key={`${mainHeaders}-${header}-txt-${user}`}
                                                                fontWeight='bold'
                                                                fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                                            >
                                                                {/* {!Object.hasOwn(courses, mainHeaders) ? 0 : Object.hasOwn(courses[indexMain], header) ? courses[indexMain][header] : 0} */}
                                                                {courses[mainHeaders] !== undefined ? (courses[mainHeaders][header] !== undefined ? courses[mainHeaders][header] : 0) : 0}
                                                            </Text>
                                                        </Td>
                                                    ))}

                                                </Fragment>
                                            ))}
                                            {/* Row level totals */}
                                            <Td
                                                key={`row-total-${user}`}
                                                textAlign='center'
                                                sx={{
                                                    paddingTop: '10px !important',
                                                    paddingBottom: '10px !important',
                                                    borderColor: 'blue.600',
                                                    bg: "yellow.200",
                                                    paddingRight: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important'),
                                                    paddingLeft: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important')
                                                }}
                                            >
                                                <Text
                                                    key={`row-total-txt-${user}`}
                                                    fontWeight='bold'
                                                    fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                                >
                                                    {userWiseTotal[user]}
                                                </Text>
                                            </Td>
                                        </Tr>

                                    ))}
                                    {/* Column total */}
                                    <Tr key={`tr-total`}>
                                        <Td
                                            key={`total-row`}
                                            sx={{
                                                paddingTop: '10px !important',
                                                paddingBottom: '10px !important',
                                                borderColor: 'red.100',
                                                paddingRight: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important'),
                                                paddingLeft: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important')
                                            }}
                                        >
                                            <Text fontWeight="bold" key={`txt-row-total`} fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}>TOTAL</Text>
                                        </Td>
                                        {Object.entries(courseWiseTotals).map(([courseName, totals]) => (
                                            <Fragment key={`${courseName}-total-fragment`}>
                                                {Object.entries(totals).map(([stage, value]) => (
                                                    <Td
                                                        key={`${courseName}-${stage}-${value}`}
                                                        textAlign='center'
                                                        bg="yellow.200"
                                                        sx={{
                                                            paddingTop: '10px !important',
                                                            paddingBottom: '10px !important',
                                                            borderColor: 'blue.600',
                                                            paddingRight: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important'),
                                                            paddingLeft: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important')
                                                        }}
                                                    >

                                                        <Text fontWeight="bold" key={`txt-total-${courseName}-${stage}`} fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}>{stage === 'RPC' ? value : ''}</Text>

                                                    </Td>
                                                ))}
                                            </Fragment>
                                        ))}

                                        <Td
                                            key={`grand-total-row`}
                                            textAlign='center'
                                            bg="yellow.200"
                                            sx={{
                                                paddingTop: '10px !important',
                                                paddingBottom: '10px !important',
                                                borderColor: 'red.100',
                                                paddingRight: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important'),
                                                paddingLeft: (isLagerThan800 ? '1.25rem !important' : isLagerThan440 ? '0.75rem !important' : '0.25rem !important')
                                            }}
                                        >
                                            <Text fontWeight="bold" key={`txt-row-grand-total`} fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}>{grandTotal}</Text>
                                        </Td>

                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    }
                </Skeleton>
            </CardBody>
        </Card>
    );
};

export default PotentialsUsersCoursewiseCount;