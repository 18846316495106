// eslint-disable-next-line
import React, { useState, useEffect, useContext } from "react";
import { store } from "../helper/Store";
import CONF from '../config.json';
import { useApi } from "./VtigerApiProvider";


const prepareRequest = (endpoint, body, header = '') => {
    let params = {
        method: 'POST',
        body: JSON.stringify(body),
    }
    if (header !== '') {
        params['headers'] = new Headers({ 'Content-Type': header });
    }
    return new Request(endpoint, params);
};

export const useFetchCustomQueryCoursewise = (module, query) => {
    const globalState = useContext(store);
    const { state, } = globalState;
    const [data, setData] = useState();

    useEffect(() => {
        const fetchCustomQuery = async () => {
            const request = prepareRequest(CONF.CRM_CUSTOM_API, { '_operation': 'fetchCustomQuery', '_session': state.token, 'module': module, 'type': 'coursewise', 'filters': query }, 'application/json');
            const response = await (await fetch(request)).json();

            setData(response);
        };

        fetchCustomQuery();
    }, [module, query, state.token]);

    return { data: data };
};

export const useFetchCustomQueryLeadsUsersCoursewise = (module) => {
    const globalState = useContext(store);
    const { state, } = globalState;
    const [data, setData] = useState();

    useEffect(() => {
        const fetchCustomQuery = async () => {
            const request = prepareRequest(CONF.CRM_CUSTOM_API, { '_operation': 'fetchCustomQuery', '_session': state.token, 'module': module, 'type': 'currentleads', 'filters': [] }, 'application/json');
            const response = await (await fetch(request)).json();

            setData(response);
        };

        fetchCustomQuery();
    }, [module, state.token]);

    return { data: data };
};

export const useFetchCustomQueryPotentialsUsersCoursewiseCount = (module, query) => {
    const globalState = useContext(store);
    const { state, } = globalState;
    const [data, setData] = useState();
    const [courseWiseTotal, setCourseWiseTotal] = useState({});
    const [userWiseTotal, setUserWiseTotal] = useState({});
    const [grandTotal, setGrandTotal] = useState(0);

    useEffect(() => {
        const fetchCustomQuery = async () => {
            const request = prepareRequest(CONF.CRM_CUSTOM_API, { '_operation': 'fetchCustomQuery', '_session': state.token, 'module': module, 'type': 'conversionpotential', 'filters': query }, 'application/json');
            const response = await (await fetch(request)).json();
            const courseTotal = getCourseWiseTotals(response.result.record);
            const userTotal = getUserWisetotal(response.result.record);


            setCourseWiseTotal(courseTotal);
            setUserWiseTotal(userTotal);
            let finalTotal = 0
            for (const [agent, total] of Object.entries(userTotal)) {
                finalTotal += total;
            }
            setGrandTotal(finalTotal)
            // console.log(userWiseTotal)
            setData(response);
        };

        fetchCustomQuery();
    }, [module, query, state.token]);

    return { data: data, courseWiseTotals: courseWiseTotal, userWiseTotal: userWiseTotal, grandTotal: grandTotal };
};
/**
 * Function to get RPC total of each user
 * @param {*} data 
 * @returns 
 */
function getUserWisetotal(data) {
    const result = {};

    for (const [agent, courses] of Object.entries(data.potentials)) {
        let totalPotentials = 0;
        for (const course of Object.values(courses)) {
            totalPotentials += course.RPC || 0;//Object.values(course).reduce((sum, val) => sum + val, 0);
        }
        result[agent] = totalPotentials;

    }
    return result;
}
/**
 * Function to prepare total row of Std councellors performance
 * @param  data 
 * @returns 
 */
function getCourseWiseTotals(data) {
    // const totals = {};
    const totals = {
        'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)': { F: 0, RFS: 0, RPP: 0, RPC: 0 },
        'Hospitality Management [Advanced Diploma] - (Full time - 2 years)': { F: 0, RFS: 0, RPP: 0, RPC: 0 },
        'Patisserie [Certificate IV] - (Full time - 1.5 years)': { F: 0, RFS: 0, RPP: 0, RPC: 0 },
        'Event Management [Diploma] - (Part time - 2 years)': { F: 0, RFS: 0, RPP: 0, RPC: 0 },
        'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)': { F: 0, RFS: 0, RPP: 0, RPC: 0 }
    };
    for (const employee in data.potentials) {
        const courses = data.potentials[employee];
        for (const course in courses) {
            const statuses = courses[course];
            // if (!totals[course]) {
            //     totals[course] = { F: 0, RFS: 0, RPP: 0, RPC: 0 };
            // }
            for (const status in statuses) {
                if (totals[course] !== undefined) {
                    totals[course][status] += statuses[status];
                }
            }
        }
    }
    return totals;
}

export const useFetchQuery = (module, select, query) => {
    const api = useApi();
    const [data, setData] = useState();

    useEffect(() => {
        const fetchQuery = async () => {
            api.fetchQuery(module, select, query).then((data) => {
                setData(data);
            });
        };

        fetchQuery();
    }, [module, select, query, api]);

    return { data: data };
};